/* eslint-disable react-hooks/exhaustive-deps */
import './ExploreContainer.css';
import {IonCard,IonCardContent,IonIcon,
  IonGrid, IonRow, IonCol,
} from '@ionic/react';
import React, { useContext,useEffect } from 'react';
import { AppContext } from '../provider/State';
import {arrowForward,arrowBack} from 'ionicons/icons';

interface ContainerProps {
  name: string;
  content:string;
  next?:string;
  prev?:string;
  nextTitle?:string;
  prevTitle?:string;
  lang?:string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name,content,next,prev,nextTitle,prevTitle,lang }) => {
  const { state, dispatch } = useContext(AppContext);
  console.log(lang);
  useEffect(() => {
    dispatch({type: 'setTitle',title: name,next:next});
  }, [name]);
  useEffect(()=>{
    createMarkup().then(()=>{console.log("update")});
  },[]);
  
  var nextbtn;
  var prevbtn;
  async function createMarkup() {
    var res= await fetch(content);
    var text=await res.text();
    dispatch({type: 'setContent',content: text});
  }
  const scrollToTop= () => {
    let content:HTMLIonContentElement= document.querySelector('ion-content#pageContent')!;
    content.scrollToTop();
  };
  if(next)
  nextbtn=<IonCol>
  <IonCard routerLink={next} routerDirection="none" onClick={scrollToTop}>
    <IonCardContent>
      {state?.data?.strings?.[state.lang]?.next} &nbsp;
      <IonIcon slot="end" src={arrowForward} mode="md"/>
      <h2>{nextTitle}</h2>
    </IonCardContent>
  </IonCard>
  </IonCol>
  // <IonButton routerLink={next} routerDirection="none">Next</IonButton>;
  else
  nextbtn=""
  if(prev)
  prevbtn=<IonCol>
  <IonCard routerLink={prev} routerDirection="none" onClick={scrollToTop}>
    <IonCardContent>
    <IonIcon slot="start" src={arrowBack} mode="md"/>
    &nbsp; {state?.data?.strings?.[state.lang]?.previous} 
    <h2>{prevTitle}</h2>
    </IonCardContent>
  </IonCard>
  </IonCol>
  //<IonButton routerLink={prev} routerDirection="none">Previous</IonButton>
  else
  prevbtn=""
  return (
    <div className="container">
      <strong>{name}</strong>
      <div id="rootcontent" dangerouslySetInnerHTML={{__html:state.content}}></div>
      <IonGrid>
        <IonRow>
          {prevbtn}
          {nextbtn}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ExploreContainer;
