import React, { createContext, useReducer } from "react";

interface MyContextType{
  data?:any;
  title?:any,
  url?:any,
  state?:any,
  dispatch?:any,
  content?:any,
  next?:any,
  lang?:any,
  scroll?:boolean
}

let AppContext = createContext<MyContextType>({
  data:null,
  title:null,
  url:null,
  dispatch:null,
  state:null,
  content:null,
  next:null,
  lang:null,
  scroll:true
})

const initialState = {
    title: 'Chapter 1',
    url: '/page/esipuhe',
    next:'/page/tiedoksi-asianosaisille',
    content:'',
    lang:'fi',
    scroll:true
}

let reducer = (state:any, action:any) => {
  switch(action.type) {
    case "setTitle": {
      return { ...state, title: action.title,next:action.next }
    }
    case "setUrl": {
        return { ...state, url: action.url }
      }
    case "setContent": {
        return { ...state, content: action.content }
      }
    case "setData": {
        return { ...state, data: action.data }
      }
    case "setLang": {
        return { ...state, lang: action.lang }
      }
    case "setScroll": {
        return { ...state, scroll: action.scroll }
      }
  }
  return state;
};

function AppContextProvider(props:any) {
  const fullInitialState = {
    ...initialState,
  }

  let [state, dispatch] = useReducer(reducer, fullInitialState);
  let value = { state, dispatch };


  return (
    <AppContext.Provider value={value as MyContextType}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };