import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar
  ,IonPopover, IonButton, IonList, IonItem,IonIcon,IonMenuToggle } from '@ionic/react';
import { useParams,useLocation,useHistory } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import {Helmet} from "react-helmet";
import React, { useContext,useEffect,useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../provider/State';
import './Page.css';
import {language,menu} from 'ionicons/icons';

const Page: React.FC = () => {

  const { name } = useParams<{ name: string; }>();
  const { state, dispatch } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const toggleMenu = ()=> {
    const splitPane = document.querySelector('ion-split-pane');
    const windowWidth = window.innerWidth;
    const splitPaneShownAt = 992;
    const when = `(min-width: ${splitPaneShownAt}px)`;
    if (windowWidth >= splitPaneShownAt) {
      // split pane view is visible
      const open = splitPane!.when === when;
      splitPane!.when = open ? false : when;
    } else {
      // split pane view is not visible
      // toggle menu open
      const menu = splitPane!.querySelector('ion-menu');
      return menu!.open();
    }
  }
  useEffect(()=>{
    let content:HTMLIonContentElement= document.querySelector('ion-content#pageContent')!;
    if(localStorage.getItem("scrollPos")!=null)
    {
      window.addEventListener("load", () => {
        // Fully loaded!
        setTimeout(()=>{
          console.log(document.readyState);
          let pos=parseInt(localStorage.getItem("scrollPos")!);
          content.scrollByPoint(0,pos,1000);
        },1000);
      });     
    }
  },[])
  return (
    <IonPage>
      <Helmet>
            <title>{state.title}</title>
        </Helmet>
        <IonPopover
          cssClass='my-custom-class'
          event={popoverState.event}
          isOpen={popoverState.showPopover}
          onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
        >
          <IonList lines="none">
            <IonItem button onClick={()=>{
              console.log("English");
              let fi=state?.data?.appPages?.filter((item:any)=> item.url.fi===location.pathname);
              if(fi?.length>0)
              {
                history.push(fi[0].url.en)
              }
              dispatch({type: 'setLang',lang: 'en'});
              setShowPopover({ showPopover: false, event: undefined });
            }}>
              English (en)
            </IonItem>
            <IonItem button onClick={()=>{
              console.log("Finnish")
              let en=state?.data?.appPages?.filter((item:any)=> item.url.en===location.pathname);
              if(en?.length>0)
              {
                history.push(en[0].url.fi)
              }
              dispatch({type: 'setLang',lang: 'fi'});
              setShowPopover({ showPopover: false, event: undefined });
            }}>
              Finnish (fi)
            </IonItem>
          </IonList>
        </IonPopover>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={toggleMenu}>
              <IonIcon src={menu}></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>{state.title}</IonTitle>
          <IonButtons slot="end">
          <IonButton onClick={
            (e: any) => {
              e.persist();
              setShowPopover({ showPopover: true, event: e })
            }}
          >
            <IonIcon src={language}></IonIcon>
          </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
        {/* <ContentPage></ContentPage> */}
        <IonContent fullscreen id="pageContent" scrollEvents={true} onIonScroll={($event) => {localStorage.setItem("scrollPos",$event.detail.scrollTop.toString())}}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{state?.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {state?.data?.appPages?.filter((item:any) => item.url[state.lang] === location.pathname).map((filterItem:any,index:any)=>{
          localStorage.setItem("currentPage",location.pathname);
          let indexs = state?.data?.appPages.findIndex( (x:any) => x.url[state.lang]=== location.pathname );
          if(indexs==0&&state?.data?.appPages.length==1)
          return(
            <ExploreContainer lang={state.lang} name={filterItem.title[state.lang]} content={state?.data?.appPages[indexs]?.content[state.lang]} key={index}/>);
          else if(indexs==0&&state?.data?.appPages.length>1)
            return(
              <ExploreContainer lang={state.lang} name={filterItem.title[state.lang]} content={state?.data?.appPages[indexs]?.content[state.lang]} key={index} 
              next={state?.data?.appPages[indexs+1]?.url[state.lang]} nextTitle={state?.data?.appPages[indexs+1]?.title[state.lang]}/>);
          else if(indexs==state?.data?.appPages.length-1)
              return(
                <ExploreContainer lang={state.lang} name={filterItem.title[state.lang]} content={state?.data?.appPages[indexs]?.content[state.lang]} key={index} 
                prev={state?.data?.appPages[indexs-1]?.url[state.lang]} prevTitle={state?.data?.appPages[indexs-1]?.title[state.lang]}/>);
          else          
          return(
          <ExploreContainer lang={state.lang} name={filterItem.title[state.lang]} content={state?.data?.appPages[indexs]?.content[state.lang]} key={index} 
          next={state?.data?.appPages[indexs+1]?.url[state.lang]} nextTitle={state?.data?.appPages[indexs+1]?.title[state.lang]} 
          prev={state?.data?.appPages[indexs-1]?.url[state.lang]} prevTitle={state?.data?.appPages[indexs-1]?.title[state.lang]}/>);
        })}
      </IonContent>
      
    </IonPage>
  );
};

export default Page;
