import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
  IonTitle
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
// import { archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp,book } from 'ionicons/icons';
import './Menu.css';
import { AppContext } from '../provider/State';
import React, { useContext,useEffect } from 'react';

interface BookInfo{
  bookTitle:string;
  iconPath?:string;
  appPages:AppPage[]
}
interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  content:string;
  prev?:string;
  next?:string;
  prevTitle?:string;
  nextTitle?:string;
}

const Menu: React.FC = () => {
  const location = useLocation();
  const { state, dispatch } = useContext(AppContext);
  useEffect(() => {
    fetch("/assets/json/content.json").then(res => res.json())
    .then((result)=>{
      dispatch({type: 'setData',data: {}});
      dispatch({type: 'setData',data: result});
    })
  }, [state.lang]);
  useEffect(() => {
  fetch("/assets/json/content.json").then(res => res.json())
    .then((result)=>{
      dispatch({type: 'setData',data: {}});
      dispatch({type: 'setData',data: result});
    })
  }, [state.title]);
  useEffect(()=>{
    console.log("location");
    let en=state?.data?.appPages?.filter((item:any)=> item.url.en===location.pathname);
    let fi=state?.data?.appPages?.filter((item:any)=> item.url.fi===location.pathname);
    if(en?.length>0 && fi?.length>0)
      dispatch({type: 'setLang',lang: state.lang});
    else if(en?.length>0)
      dispatch({type: 'setLang',lang: 'en'});
    else if(fi?.length>0)
      dispatch({type: 'setLang',lang: 'fi'});
    console.log(state.lang);
  },[location,state.data]);
  const scrollToTop= () => {
    let content:HTMLIonContentElement= document.querySelector('ion-content#pageContent')!;
    content.scrollToTop();
  };
    return (
      <IonMenu contentId="main" type="overlay">
        <IonContent>
        <IonToolbar style={{position:'fixed',top:'0px'}}>
              <IonIcon src={state?.data?.iconPath} slot="start" style={{padding:'10px 20px',fill:'rgb(255, 209, 57)'}}></IonIcon>
              <IonTitle size="large">{state?.data?.bookTitle?.[state.lang]}</IonTitle>
        </IonToolbar>
          <IonList id="inbox-list" lines="none">
            {state?.data?.appPages?.map((appPage:any, index:any) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem className={location.pathname === appPage.url?.[state.lang] ? 'selected' : ''} 
                  routerLink={appPage.url?.[state.lang]} routerDirection="none" lines="none" detail={false} onClick={scrollToTop} >
                    <IonLabel>{appPage.title?.[state.lang]}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
        </IonContent>
      </IonMenu>
    );
};

export default Menu;
